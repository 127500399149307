<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Vendors({{ count }})</h5>
			</CCol>
	     		 <CCol lg="6">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
						<CButton 
						v-if="config.getPermission('vendor').create"
						size="sm" style="margin-right: 10px" 
						color="success" 
						target="_blank " href="#/data/create_vendor" 
						class="float-lg-right"><i class="fa fa-plus"></i>Add Vendor
						</CButton>
						<CButton 
						v-if="config.getPermission('vendor').download"
						size="sm" style="margin-right: 10px" 
						color="btn btn-outline-primary" @click="download()" 
						class="float-lg-right"><i class="fa fa-download"></i>Download
						</CButton>
		      		
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	      
		      <CCol lg="12">  
				  <div class="table-responsive-sm table-responsive-md">
	      		<CTabs variant="pills" :active-tab="0">
					<CTab title="All" @click.native="setStatus('')">
				    	<br/>
						
				      	<CDataTable
						  :loading="isLoading"
					      	:items="dataListAll.data"
					      	:fields="fields"
							hover
							striped
							outlined
							
					    >  
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
							</template>
					    	
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('vendor').view" @click="viewModal(item)"><i class="fa fa-eye"> View</i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('vendor').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('vendor').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageAll"
				          	:pages="Math.ceil(dataListAll.total / 20)"
				          	:activePage="currentPageAll"
				          	@update:activePage="updatePageAll"
				        />
				    </CTab>
				  
						<CTab title="Active" @click.native="setStatus('active')">
				    	<br/>
						
				      	<CDataTable
						  :loading="isLoading"
					      	:items="dataList.data"
					      	:fields="fields"
							hover
							striped
							outlined
							
							
					    >  
					    	<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
							</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('vendor').view" @click="viewModal(item)"><i class="fa fa-eye"> View</i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('vendor').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('vendor').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem>  
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPage"
				          	:pages="Math.ceil(dataList.total / 20)"
				          	:activePage="currentPage"
				          	@update:activePage="updatePage"
				        />
				    </CTab>
				  
					 <CTab title="Inactive" @click.native="setStatus('inactive')">
				    	<br/>
						
				      	<CDataTable
						  :loading="isLoading"
					      	:items="dataListInactive.data"
					      	:fields="fields"
							hover
							striped
							outlined
							
							
					    >  
					    	<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
							</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('vendor').view" @click="viewModal(item)"><i class="fa fa-eye"> View</i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('vendor').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('vendor').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageInactive"
				          	:pages="Math.ceil(dataListInactive.total / 20)"
				          	:activePage="currentPageInactive"
				          	@update:activePage="updatePageInactive"
				        />
				    </CTab> 
				</CTabs> 
			 </div>
	      	</CCol>
	    </CRow>
      
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {
	mounted(){ 
		this.getDataAll();
		this.getData();
		this.getDataInactive();
		this.getVendorType();
		for(var i = 0; i < document.querySelectorAll('.nav-item').length; i++){
			document.querySelectorAll('.nav-item')[i].onclick = ()=>{
				this.current_tab = document.querySelector(".nav-link.active").innerHTML;
				this.setCount();
			}
		}
		
		
	},
	data(){
		return{
			title:'',
			isLoading: false,
			config,
			formModal: false,
			count:0,
			showViewModal: false,
			dataListAll: [],
			dataList: [],
			dataListInactive: [],
			depotName: "",
			dataParams: {
				vendor_name: "",
				contact_no: "",
				email: "",
				address_a: "",
				address_b: "",
				owners_name: "",
				owners_contact_no: "",
				coordinator_name: "",
				coordinator_contact_no: "",
				vendor_type: "",
				tax_type: "",
				status: "active",
				date_started: "",
				date_seperated: ""
			},
			fields: [
				{
					key: 'vendor_name', 
					label: 'Name'
				},
				{
					key: 'vendor_type_label', 
					label: 'Type'
				},
				// {
				// 	key: 'email_label', 
				// 	label: 'Email'
				// },
				{
					key: 'address_a_label', 
					label: 'Address'
				},
				{
					key: 'contact_no_label', 
					label: 'Contact'
				},
				{
					key: 'status', 
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPage: 1,
			currentPageAll:1,
			currentPageInactive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				vendor_name: "",
				vendor_type: "",
				address_a: "",
				contact_no: "",
				email: "",
			},
			vendorTypeList: {
				data: []
			},
			selected_status : '',
			current_tab : 'All'
		}
	},
	name: 'Tables',
	components: {vSelect, Search, Datepicker},
	methods: {
		setStatus(status) {
			this.selected_status = status;
		},
		download() {
			this.$showLoading(true)
			axios.get(config.api_path+'/vendor/report/list',{
				params: {
					 vendor_name: this.filter.vendor_name,
           			 vendor_type: this.filter.vendor_type,
            		 address_a: this.filter.address_a,
           			 contact_no: this.filter.contact_no,
					status : this.selected_status
				}
			}).then(response => {
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
	
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
			}).catch(err => {
				this.$showLoading(false)
			})
		},

		setCount(){
			if(this.current_tab == "All"){
				this.count = this.dataListAll.total
			}
			if(this.current_tab == "Active"){
				this.count = this.dataList.total
			}
			if(this.current_tab == "Inactive"){
				this.count = this.dataListInactive.total
			}
		},
		 getBadge (status) {
    	 return status === 'active' ? 'success'
             : status === 'inactive' ? 'danger' : 'primary'
    },
		updatePage(data){
			this.currentPage = data;
			this.getData();
		}, 
		 updatePageAll(data){
			this.currentPageAll = data;
			this.getDataAll();

		}, 
		updatePageInactive(data){
			this.currentPageInactive = data;
			this.getDataInactive();
		}, 


	    search(event){
	    	this.filter = event;
			this.getDataAll(); 
	    	this.getData();
	    	this.getDataInactive();
	    },

	    getData(){
			this.isLoading=true;
	    	var vendor_type = this.filter.vendor_type;
	    	if(vendor_type == "all" || vendor_type == null){
	    		vendor_type = ""
	    	}
	    	axios.get(config.api_path+'/vendor?status=active&vendor_name='+this.filter.vendor_name+'&email='+this.filter.email+'&vendor_type='+vendor_type+'&address_a='+this.filter.address_a+'&contact_no='+this.filter.contact_no+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
	    			if(value.account_code == ""){
	    				value.account_code = "N/A";
	    			}
					value.email_label = "N/A"
	    			if(value.email){
	    				value.email_label = value.email;
	    			}
	    			value.vendor_type_label = "N/A"
	    			if(value.vendor_type_name){
	    				value.vendor_type_label = value.vendor_type_name.setting_name;
	    			}

					value.contact_no_label = value.contact_no ?? '';
					// value.address_a_label = value.address_a ?? '';
					value.address_a_label = value.address_a ? this.$options.filters.truncate(value.address_a):' ';
					
	    			return value;
	    		}); 
				this.setCount()	
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },
		getDataAll(){
			this.isLoading=true;
	    	var vendor_type = this.filter.vendor_type;
	    	if(vendor_type == "all" || vendor_type == null){
	    		vendor_type = ""
	    	}
	    	axios.get(config.api_path+'/vendor?status=&vendor_name='+this.filter.vendor_name+'&email='+this.filter.email+'&vendor_type='+vendor_type+'&address_a='+this.filter.address_a+'&contact_no='+this.filter.contact_no+'&page='+this.currentPageAll+'&limit=20')
	    	.then(response => {
	    		this.dataListAll = response.data; 
	    		this.dataListAll.data = this.dataListAll.data.map((value, index)=>{
	    			if(value.account_code == ""){
	    				value.account_code = "N/A";
	    			}
	    			value.vendor_type_label = "N/A"
	    			if(value.vendor_type_name){
	    				value.vendor_type_label = value.vendor_type_name.setting_name;
	    			}

	    			value.email_label = "N/A"
	    			if(value.email){
	    				value.email_label = value.email;
	    			}
					value.contact_no_label = value.contact_no ?? '';
					value.address_a_label = value.address_a ? this.$options.filters.truncate(value.address_a):' ';
					
					
	    			return value;
	    		}); 
				this.setCount()
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataInactive(){
			this.isLoading=true;
	    	var vendor_type = this.filter.vendor_type;
	    	if(vendor_type == "all"){
	    		vendor_type = ""
	    	}
	    	axios.get(config.api_path+'/vendor?status=inactive&vendor_name='+this.filter.vendor_name+'&email='+this.filter.email+'&vendor_type='+vendor_type+'&address_a='+this.filter.address_a+'&contact_no='+this.filter.contact_no+'&page='+this.currentPageInactive+'&limit=20')
	    	.then(response => {
	    		this.dataListInactive = response.data; 
	    		this.dataListInactive.data = this.dataListInactive.data.map((value, index)=>{
	    			if(value.account_code == ""){
	    				value.account_code = "N/A";
	    			}
					value.email_label = "N/A"
	    			if(value.email){
	    				value.email_label = value.email;
	    			}
	    			value.vendor_type_label = "N/A"
	    			if(value.vendor_type_name){
	    				value.vendor_type_label = value.vendor_type_name.setting_name;
	    			}
					
					value.contact_no_label = value.contact_no ?? '';
					value.address_a_label = value.address_a ? this.$options.filters.truncate(value.address_a):' ';
	    			return value;
	    		}); 
				this.setCount()
				
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    updateModal(item){

	    	// this.selectedRow = item;
	    	// this.dataParams = {...item};
	    	// this.formModal = true;
	    	// this.editMode = true;
			// this.title = "UPDATE VENDOR";
			window.open('/#/data/update_vendor/'+item.id, '_blank');

	    },

	    viewModal(item){

	    	// this.selectedRow = item;
	    	// this.dataParams = item;
	    	// this.showViewModal = true; 

			window.open('/#/data/view_vendor/'+item.id, '_blank');
	    },

	    deleteRow(item){
	    	Swal.fire({ 
				icon:'warning',
				title: 'Are you sure you want to delete this vendor?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/vendor/'+item.id)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "Vendor successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    },

	  
 	}
}
</script>
